<template>
  <div class="min-h-screen flex items-center justify-center animate-gradient">
    <div class="bg-black bg-opacity-80 backdrop-blur-lg p-8 shadow-lg w-full max-w-md text-center transition-all duration-500 ease-in-out transform hover:scale-105 hover:shadow-2xl irregular-box">
      <img
        class="w-32 h-32 mx-auto rounded-full border-4 border-white mb-4 animate__animated animate__pulse"
        src="@/assets/images/profile.jpg"
        alt="Your Photo"
        style="animation: rotate-360 5s linear infinite;"
      />
      <h1 class="text-4xl font-bold text-gold mb-2 font-dancing animate__animated animate__fadeIn">{{ name }}</h1>
      <h2 class="text-xl font-medium text-gold mb-4 font-sans animate__animated animate__fadeIn">{{ jobTitle }}</h2>

      <ul class="flex justify-center space-x-6 mb-4">
        <li>
          <a :href="telegram" target="_blank" class="text-gold hover:text-yellow-400 transition-colors duration-300 transform hover:scale-110">
            <i class="fab fa-telegram-plane h-8 w-8"></i>
          </a>
        </li>
        <li>
          <a :href="instagram" target="_blank" class="text-gold hover:text-yellow-400 transition-colors duration-300 transform hover:scale-110">
            <i class="fab fa-instagram h-8 w-8"></i>
          </a>
        </li>
      </ul>

      <p class="text-gold font-sans text-lg">
        <a :href="emailLink" class="text-yellow-300 font-bold">Email</a> me for more information!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "Amir Esmaeili",
      jobTitle: "Software engineer",
      telegram: "https://t.me/aboutamir_zzz",
      instagram: "https://instagram.com/aboutamirzz",
      email: "me@itamir7.ir",
    };
  },
  computed: {
    emailLink() {
      return `mailto:${this.email}`;
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400&display=swap');

/* Custom rotate animation for profile pic */
@keyframes rotate-360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Gradient background animation */
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  animation: gradient-animation 10s ease infinite;
  background-size: 200% 200%;
  background-image: linear-gradient(45deg, #000000, #ffde03); /* Black to gold gradient */
}

/* Modern rounded square box styling */
.irregular-box {
  border-radius: 24px; /* Rounded edges */
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem; /* Adjust heading size */
  }
  h2 {
    font-size: 1.25rem; /* Adjust subheading size */
  }
  .text-lg {
    font-size: 0.875rem; /* Adjust paragraph size */
  }
}

/* Gold color */
.text-gold {
  color: #ffde03; /* Gold color */
}

/* Apply the imported font */
.font-dancing {
  font-family: 'Dancing Script', cursive;
}

ul li a {
  font-size: 1.5rem;
}
</style>
